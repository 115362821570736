import React from 'react'

import { Instagram, Facebook } from 'react-feather'

import './Footer.css'

const INSTAGRAM_URL = 'https://www.instagram.com/nasmijani.namcor'
const FACEBOOK_URL = 'https://facebook.com/namcornasmijani'

export default () => (
  <div>
    <footer className="footer">
      <div className="container">
        <span>
          © Sva prava zadržana | {new Date().getFullYear()} |{' '}
          <a className="footer-title" href={'/'}>
            Nasmijani Namćor
          </a>
        </span>

        <div>
          <a href={INSTAGRAM_URL} rel="noreferrer" target="_blank">
            <Instagram size="4rem" />
          </a>

          <a href={FACEBOOK_URL} rel="noreferrer" target="_blank">
            <Facebook size="4rem" style={{ marginLeft: 8 }} />
          </a>
        </div>
      </div>
    </footer>
  </div>
)
