import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

export const metaKeywords = 'Nasmijani, Namćor, Nasmijani, Namcor, Knjige, Book, Books, Knjiga, Književnost, Pisanje, literature, Writing, Writer, Preporuka knjige, Poezija, Priča, Short story, Short stories, Kratka priča, Kratke priče, Namćorisanja, Namcorisanja, Storytelling, Storyteller, Poet, Poetry, Citati, Quotes, Žanrovi, Žanr, Razgovori, Talk, Pjesnik, Pjesnici, Intervju, interview, Word, Words, Riječ, Riječi, Letter, Slovo, Recenzije, review';

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      meta {
        title
        description
        noindex
        canonicalLink
      }
    }
  }
`

export default class Meta extends Component {
  render() {
    const {
      title,
      url,
      description,
      absoluteImageUrl = '',
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      siteTitle,
      siteDescription
      // overwrite { title, description } if in fields or fields.meta
    } = this.props

    return (
      <Helmet>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} />}
        {description && <meta name="description" content={description} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {url && <meta property="og:type" content="website" />}
        {url && <meta property="og:url" content={url} />}
        {twitterSiteAccount && (
          <meta name="twitter:site" content={twitterSiteAccount} />
        )}
        {twitterCreatorAccount && (
          <meta name="twitter:creator" content={twitterCreatorAccount} />
        )}
        {noindex && <meta name="robots" content="noindex" />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:image" content={absoluteImageUrl} />
        <meta property="og:image:secure_url" content={absoluteImageUrl} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta name="twitter:card" content={absoluteImageUrl} />
        <meta name="keywords" contentexport ={metaKeywords} />
      </Helmet>
    )
  }
}
